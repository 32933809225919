.fomtsizetest {
 font-size: 45px;
}
.fomtsizetest1 {
 font-size: 18px;
}
.fontsizeset2 {
    font-size: 30px;
}
.fontsizeset3 {
    font-size: 30px;
}
.fontsizeset4 {
    font-size: 35px;
}
.fontsizeset5 {
    font-size: 25px;
}
.fontsizeset6 {
    font-size: 35px;
}
@media (max-width: 837px) {
    .fomtsizetest {
        font-size: 35px;
       }
       .fontsizeset2 {
        font-size: 21px;
    }
    .fontsizeset3 {
        font-size: 18px;
    }
    .fontsizeset4 {
        font-size: 30px;
    }
    .fontsizeset5 {
        font-size: 25px;
    }
    .fontsizeset6 {
        font-size: 30px;
    }
}
