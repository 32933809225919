.button-overlay2 {
    position: relative;
    display: inline-block;
}

.button-overlay2 button {
    position: absolute;
    top: 60px;
    left: 85%;
    transform: translate(-50%, -50%);
    width: 365px;
    height: 85px;
}
