@media (max-width: 837px) {
  .carousel-caption {
    font-size: 25px;
    /* font-weight: bolder; */
    margin-bottom: 10% !important;
    margin: 0 !important;
    /* text-align: center !important; */
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    height: 90% !important;
    width: 80%;
  }
}







