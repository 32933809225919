body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.fontfamilyAvenir{
  font-family: 'Avenir', sans-serif;
}

.AcerhealthGreen{
  background-color: #0F8A07;
}

.AcerhealthPurple{
  /* background-color: #582C83; */
  background-color: #3B0075;
}

.AcerhealthTextGreen{
  color: #0F8A07;
}

.AcerhealthTextPurple{
  /* color: #582C83; */
  color: #3B0075;
}

.AcerhealthGray{
  background-color: #FDFEFE;;
}

.section{
  margin-top:120px;
}

.display-7 {
  font-size: 2.0rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-8 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-9 {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-heading {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-subheading {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;
}

.justify{
  text-align: justify;
}
  
.case-studies{ text-align: left}
.box1{background:#CCFFBB}
.box2{background:#FFD766}
.box3{background: #96e6ff}
.box4{background:#FFA364}
.box5{background:#ff5400}
.box6{background:#8ea0f5}
.box7{background:#592c81}
.box8{background:#592c81}
.case-studies2 .cs_box,
/* box content */
.case-studies3 .cs_box{border-radius:10px;color:#fff;font-weight:400;padding:50px}.case-studies1 .cs_box h3,
.case-studies2,
.case-studies3{padding-bottom:30px}

.slide-in {
  transform: translateX(100%);
  animation: slideInFromRight 2s ease-in-out forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}



.slide-in-from-left {
  transform: translateX(-100%);
  animation: slideInFromLeft 2s ease-in-out forwards;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.mask1 {
  -webkit-mask-image: linear-gradient(to bottom, rgba(0.9, 0.9, 0.9, 0.9), transparent);
  mask-image: linear-gradient(to bottom, rgba(0.9, 0.9, 0.9, 0.9), transparent);
}