.section-heading1 {
    margin-bottom: 50px;
}
.servicespace3 {
    
    font-size: 45px;
}
.servicespace1 {
    font-size: 30px; 
}
.servicespace2 {
    font-size: 18px;
}
.servicespace {
    font-size: 20px;
    text-align: center;
}
.servicespace {
    font-size: 20px;
}
@media (max-width: 768px) {
.section-heading1 {
    margin-bottom: 30px;
}
.servicespace {
    font-size: 18px;
    justify-content: center;
}
.servicespace1 {
    font-size: 21px; 
    text-align: center;
}

.servicespace2 {
    font-size: 18px;
    text-align: center;
}

.servicespace3 {
    font-size: 35px;
}
}