.button-overlay {
    position: relative;
    display: inline-block;
}
.button-overlay button {
    position: absolute;
    top: 110px;
    left: 32%;
    transform: translate(-50%, -50%);
    width:300px;
    height:60px;
}

  @media (min-width: 866px) and (max-width: 1024px) {
    .button-overlay {
      display: none; /* Hide the entire button overlay on screens smaller than 768px */
    }
  }