.button-overlay1 {
    position: relative;
    display: inline-block;
}

.button-overlay1 button {
    position: absolute;
    top: 170px;
    left: 61%;
    transform: translate(-50%, -50%);
    width: 210px;
    height: 160px;
}
