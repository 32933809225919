.button-overlay3 {
    position: relative;
    display: inline-block;
}

.button-overlay3 button {
    position: absolute;
    top: 190px;
    left: 37%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 55px;
}


