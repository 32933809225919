.carousel-wrapper {
    position: relative;
  }
  

  @media only screen and (min-width: 767px) {
    /* For Mobile: */
    .carousel-captions {
      position: absolute;
      top:0px;
      width:100%;
    }
    .carousel-caption{
      height:200px;
    }
  
    .banner-text1{
  
      font-size: 30px;
      font-weight: 600;
      font-family: 'Roboto';
  
    }
  
  }


  @media (min-width: 767px) and (max-width: 991.98px) {
  /* For tablets: */
  .carousel-captions {
    position: absolute;
    top:0px;
    width:100%;
    margin-top: 520px;
  }
  .carousel-caption{
    height:500px;
  }

  .banner-text1{
    font-size: 35px;
    font-weight: 300;
    font-family: 'Roboto';
  }

  .banner-text2{
    font-size: 50px;
    font-weight: 500;
    font-family: 'Roboto';
  }

  .servicetest {
    margin-top: 370px;
  }

  .service-wrapper {
   height: 650px;
  }

}

@media only screen and (min-width: 800px) {
  /* For desktop: */
  .carousel-captions {
    position: absolute;
    top:30px;
    left:30px;
    width:30%;
  }

  .carousel-caption{
    height:500px;
  }

  .banner-text1{
    font-size: 35px;
    font-weight: 300;
    font-family: 'Roboto';
  }

  .banner-text2{
    font-size: 50px;
    font-weight: 400;
    font-family: 'Roboto';
  }

  .service-wrapper {
    height: 590px;
   }
}  

/* contact form input box shadow */
.shadow-box {
  box-shadow: 4px 4px 5px rgba(160, 152, 152, 0.7);
}

.radio-group {
  display: flex;
}


