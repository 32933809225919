

.cardtitletest {
    font-size: 23px;
}
.cardtitletest1 {
    font-size: 23px;
}
@media (max-width: 866px) and (min-width: 601px) {
.cardtest1 {
    width:175px;
}
.cardtitletest {
    font-size: 16px;
}
.cardtitletest1 {
    font-size: 18px;
}

}