
.profile {
    width:700px;
    height:400px;
    }
.speciality {
    font-size: 45px;
}
.speciality1 {
    font-size: 20px;
}
@media (max-width: 768px) {
.profile {
    width:100%;
    height: 100%;
}
.speciality {
    font-size: 35px;
}
.speciality1 {
    font-size: 18px;
    text-align: center;
}
}