.testomonial {
    font-size: 45px;
}
.testomonial1 {
    font-size: 20px;
    
}
@media (max-width: 837px) {

    .testomonial {
        font-size: 30px;
    }

    .testomonial1 {
        font-size: 18px;
        text-align: center;
    }
    .testomonial2 {
        font-size: 18px;
        text-align: center;
    }

}