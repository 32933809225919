
.testabout {
    font-size: 20px;
}
.testourvalue {
    font-size: 45px;
}
.testourvalues1 {
    font-size: 30px; 
}
@media (max-width: 837px) {
    .testabout {
        font-size: 18px;
    }
    .testourvalue {
        font-size: 35px;
    }
    .testourvalues1 {
        font-size: 21px; 
    }
}