.section-heading {
    height: 200px;
}
.testimage {
    width: 60%;
}
.testpadding {
    padding-bottom: 10px;
}
.rcmservices1 {
    font-size: 20px;
}
.custom-video {
    width: 700px;
    height: 600px;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
@media (max-width: 837px) {
    .section-heading {
        height: 80px;
        padding-top: 15px;
        align-items: center;
        justify-content: center;
    }

    .testimage {
        width: 100%;
    }

    .rcmservices {
        font-size: 35px;
    }

    .rcmservices1 {
        font-size: 18px;
        text-align: center;
    }
    .custom-video {
        width: auto;
        height: auto;
        max-width: none;
        max-height: none;
      }


}
