.testparallax13 {
  background-image: url('../img/parallax13.svg');
  height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  }

@media (max-width: 837px) {
  .testparallax13 {
    background-attachment: scroll;
    background-size: contain;
    height: 200px; /* Adjust the height as needed for mobile screens */
  }
}

@media (max-width: 866px) and (min-width: 601px) {
  .testparallax13 {
    background-attachment: scroll;
    background-size: contain;
    height: 500px; /* Adjust the height as needed for tablet screens */
  }
}
